/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:21b581c0-3028-45b6-9935-f85c37e4c91f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Ie3tNxMod",
    "aws_user_pools_web_client_id": "3jc1s6f9akp2r242lnrhaapfkg",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://h2r5654vqrbcxgvyovclrczna4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-mjk4dsdz4fbknas3xfeyys5lsi"
};


export default awsmobile;
