const Home = () => {
    return (
        <div className="home">
            <h2>Welcome to Gay Bear Capital</h2>
            <p>
            </p>
        </div>
    );
}
 
export default Home;